var wamadmin = {
  'system_users_appprojects': {
    'w_id_list': [],
    'layer_id': '93',
    'wdm_type': 'wdm316'
  },
  'system_users_appprojects_appbranchs': {
    'w_id_list': [],
    'layer_id': '94',
    'wdm_type': 'wdm317'
  },
  'system_users_appprojects_appbranchs_appconfigs': {
    'w_id_list': [],
    'layer_id': '108',
    'wdm_type': 'wdm330'
  },
  'system_users_appprojects_appbranchs_appversions': {
    'w_id_list': [],
    'layer_id': '109',
    'wdm_type': 'wdm331'
  },
  'system_users_appprojects_appbranchs_appfriends': {
    'w_id_list': [],
    'layer_id': '95',
    'wdm_type': 'wdm318'
  },
  'system_users_appprojects_appbranchs_appids': {
    'w_id_list': [],
    'layer_id': '96',
    'wdm_type': 'wdm319'
  },
  'system_users_appprojects_appbranchs_applocations': {
    'w_id_list': [],
    'layer_id': '97',
    'wdm_type': 'wdm320'
  },
  'system_users_appprojects_appbranchs_apprequirements': {
    'w_id_list': [],
    'layer_id': '98',
    'wdm_type': 'wdm321'
  },
  'system_users_appprojects_appbranchs_approutes': {
    'w_id_list': [],
    'layer_id': '99',
    'wdm_type': 'wdm322'
  },
  'system_users_appprojects_appbranchs_appstatuscodes': {
    'w_id_list': [],
    'layer_id': '100',
    'wdm_type': 'wdm323'
  },
  'system_users_appprojects_appbranchs_appcodepages': {
    'w_id_list': [],
    'layer_id': '101',
    'wdm_type': 'wdm324'
  },
  'system_users_appprojects_appbranchs_appdocchanges': {
    'w_id_list': [],
    'layer_id': '102',
    'wdm_type': 'wdm325'
  },
  'system_users_ecosystems': {
    'w_id_list': [],
    'layer_id': '103',
    'wdm_type': 'wdm326'
  },
  'system_users_ecosystems_servers': {
    'w_id_list': [],
    'layer_id': '104',
    'wdm_type': 'wdm327'
  },
  'system_users_ecosystems_servers_requirements': {
    'w_id_list': [],
    'layer_id': '105',
    'wdm_type': 'wdm328'
  },
  'system_users_ecosystems_servers_applications': {
    'w_id_list': [],
    'layer_id': '106',
    'wdm_type': 'wdm316'
  },
  'system_users_ecosystems_servers_codepages': {
    'w_id_list': [],
    'layer_id': '107',
    'wdm_type': 'wdm324'
  }
};

export { wamadmin };
