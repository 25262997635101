<template>
  <div>
    <b-card no-body header-tag="header" :style="'margin-bottom: 2px; background-color:' + p_menuData.style.backgroundColor + '; color:' + p_menuData.style.color" key="upper_side">
      <b-tabs>
        <b-tab v-for="(menu1, menu1_ind) in p_menuData.list" :active="menu1.value === d_selectedMenu" @click="d_selectedMenu = menu1.value">
          <template slot="title">
            <img :src="menu1.icon" style="width: 1.5em;">
            <span :style="'color:' + p_menuData.style.tab_header.color">{{ menu1.label }}</span>
          </template>
          <template v-if="menu1.value === d_selectedMenu">
            <b-row style="margin-left: 0px;;">
              <b-col cols="12">
                <div :class="[device.isMobile ? 'menu1-item-icon-mobile' : 'menu1-item-icon']">
                  <img :src="menu1.icon" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" />
                </div>
                <template v-for="(menu2, menu2_ind) in menu1.list">
                  <div :style="device.isMobile ? '' : 'height: ' + p_menuData.style.height + 'px;'" :class="[device.isMobile ? 'menu2-item-mobile' : 'menu2-item']">
                    <template v-if="menu2.label">
                      {{ menu2.label }}
                      <br>
                    </template>
                    <template v-for="(menu3, menu3_ind) in menu2.list">
                      <div :class="device.isMobile ? 'menusubitem-mobile' : 'menusubitem'">
                        <template v-if="menu3.type === 'button'">
                          <b-button :title="menu3.title" variant="white" size="sm" @click="menu3.click">
                            <img :src="menu3.icon" style="width: 2em;" />
                            <span :style="'color:' + p_menuData.style.subColor"> {{ menu3.label }} </span>
                          </b-button>
                        </template>
                        <template v-else-if="menu3.type === 'checklist'">
                          <b-card class="card-accent-secondary" no-body style="color: black; margin-bottom: 0px;">
                            <b-card-header header-bg-variant="white" header-text-variant="dark" class="p-1" :title="menu3.title">
                              <img :src="menu3.icon" class="img-rounded img-responsive" style="border-radius: 50%; width: 2em;" /> {{ menu3.label }}
                              <b-button class="pull-right" variant="white" size="sm" @click="f_addRemoveAllOptions(menu3.option_list, menu3.selected_option_list, menu3.checklist_type)"><i class="fa fa-check"></i></b-button>
                            </b-card-header>
                            <div id="s_operationsScrollbar" :class="[device.isMobile ? 'menu-checklist-mobile' : 'menu-checklist']">
                              <template v-for="(opt, opt_ind) in menu3.option_list">
                                <b-row :class="f_calculateOptionClass(opt.value, menu3.selected_option_list)" @click="f_addRemoveOption(opt.value, menu3.selected_option_list, menu3.checklist_type)">
                                  <b-col cols="12" style="padding: 1px;" :title="opt.label">
                                    <template v-if="menu3.max_option_label_len">
                                      <template v-if="menu3.selected_option_list.indexOf(opt.value) !== - 1">
                                        <img :src="menu3.icon" style="width: 1.2em;" /> {{ opt.label.length > 30 ? opt.label.substring(0, menu3.max_option_label_len) + '...' : opt.label }}
                                      </template>
                                      <template v-else>
                                        <i class="fa fa-caret-right" style="width: 1.2em;"></i> {{ opt.label.length > 30 ? opt.label.substring(0, menu3.max_option_label_len) + '...' : opt.label }}
                                      </template>
                                      <!-- <i :class="[menu3.selected_option_list.indexOf(opt.value) !== - 1 ? 'fa fa-check' : 'fa fa-caret-right']"></i> {{ opt.label }} -->
                                    </template>
                                    <template v-else>
                                      <template v-if="menu3.selected_option_list.indexOf(opt.value) !== - 1">
                                        <img :src="menu3.icon" style="width: 1.2em;" /> {{ opt.label }}
                                      </template>
                                      <template v-else>
                                        <i class="fa fa-caret-right" style="width: 1.2em;"></i> {{ opt.label }}
                                      </template>
                                      <!-- <i :class="[menu3.selected_option_list.indexOf(opt.value) !== - 1 ? 'fa fa-check' : 'fa fa-caret-right']"></i> {{ opt.label }} -->
                                    </template>
                                  </b-col>
                                </b-row>
                              </template>
                            </div>
                          </b-card>
                        </template>
                        <template v-else-if="menu3.type === 'link'">
                          <b-button :title="menu3.title" variant="white" size="sm" @click="f_goToLink(menu3.link)">
                            <img :src="menu3.icon" style="width: 2em;" /> {{ menu3.label }}
                          </b-button>
                        </template>
                        <template v-else-if="menu3.type === 'buttongroup'">
                          <b-dropdown variant="white" style="padding: 0px;">
                            <template slot="button-content">
                              <img :src="menu3.icon" style="border-radius: 50%; width: 2em;" />
                              <span :style="'color:' + p_menuData.style.color">{{ menu3.label }}</span>
                            </template>
                            <template v-for="(menu4, menu4_ind) in menu3.list">
                              <template v-if="menu4.type === 'button'">
                                <b-dropdown-item @click="menu4.click">
                                  <img :src="menu4.icon" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> {{ menu4.label }}
                                </b-dropdown-item>
                              </template>
                              <template v-else-if="menu4.type === 'link'">
                                <b-dropdown-item @click="f_goToLink(menu4.link)">
                                  <img :src="menu4.icon" class="img-rounded img-responsive" style="border-radius: 50%; width: 1.5em;" /> {{ menu4.label }}
                                </b-dropdown-item>
                              </template>
                            </template>
                          </b-dropdown>
                        </template>
                        <template v-else-if="menu3.type === 'color'">
                          <div style="width: 50px; float: left;" :title="menu3.title">{{ menu3.label }}</div>
                          <input style="border: 0px; padding: 0px; margin-right: 5px;" type="color" :title="menu3.title" v-model="menu3.color" @change="f_changeStyle()">
                        </template>
                      </div>
                    </template>
                  </div>
                </template>
              </b-col>
            </b-row>
          </template>
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import moment from 'moment';
export default {
  name: 'WTopMenu',
  computed: mapGetters({
    lang: 'lang',
    device: 'device'
  }),
  props: {
    p_menuData: {
      type: Object,
      required: true
    }
  },
  components: {},
  data () {
    return {
      d_selectedMenu: ''
    };
  },
  created: function () {
    if (this.p_menuData.selected_menu && this.p_menuData.selected_menu !== '') {
      this.d_selectedMenu = this.p_menuData.selected_menu;
    }
  },
  mounted: function () {},
  methods: {
    f_addRemoveAllOptions: function (option_list, selected_option_list, checklist_type) {
      if (checklist_type === 'all_selections') {
        if (selected_option_list.length > 0) {
          selected_option_list.splice(0, selected_option_list.length);
        } else {
          for (let i in option_list) {
            selected_option_list.push(option_list[i].value);
          }
        }
        this.$forceUpdate();
      }
    },
    f_addRemoveOption: function (opt_value, selected_option_list, checklist_type) {
      if (checklist_type === 'all_selections') {
        if (selected_option_list.indexOf(opt_value) !== -1) {
          selected_option_list.splice(selected_option_list.indexOf(opt_value), 1);
        } else {
          selected_option_list.push(opt_value);
        }
      } else if (checklist_type === 'only_one') {
        selected_option_list.splice(0, selected_option_list.length);
        selected_option_list.push(opt_value);
      }
      this.$forceUpdate();
    },
    f_calculateOptionClass: function (opt_value, selected_option_list) {
      if (selected_option_list.indexOf(opt_value) !== -1) {
        return 's_rowSelected';
      } else {
        return 's_rowNotSelected';
      }
    },
    f_goToLink: function (uri) {
      window.open(uri, '_blank');
    },
    f_changeStyle: function () {
      return;
      setTimeout(function () {
        this.$forceUpdate();
      }.bind(this), 300)
    }
  },
  watch: {
    'p_menuData.forceUpdate': function () {
      this.$forceUpdate();
    }
  }
};

</script>

<style type="text/css">
.menusubitem {
  float: left;
  min-width: 50px;
}

.menusubitem-mobile {
  width: 100%;
}

.menu-checklist {
  min-height: 120px;
  max-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px 0 0 10px;
  padding: 3px
}

.menu-checklist-mobile {
  width: 100%;
  min-height: 120px;
  max-height: 100px;
  overflow-y: scroll;
  overflow-x: hidden;
  border-radius: 10px 0 0 10px;
  padding: 3px
}

.menu2-item {
  float: left;
  min-width: 75px;
  padding: 3px
}

.menu2-item-mobile {
  width: 100%;
  padding: 3px
}

.menu1-item-icon {
  float: left;
}

.menu2-item-icon-mobile {
  width: 100%;
}

#s_operationsScrollbar::-webkit-scrollbar-track {
  border-radius: 0 10px 10px 0;
  background-color: #d9e9ea;
}

#s_operationsScrollbar::-webkit-scrollbar {
  width: 5px;
  /*background-color: #F5F5F5;*/
}

#s_operationsScrollbar::-webkit-scrollbar-thumb {
  border-radius: 0 10px 10px 0;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .3);
  background-color: #4788ac;
}

.s_rowSelected {
  margin: 0px;
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowSelected:hover {
  background-color: #9effc08f;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowNotSelected {
  margin: 0px;
  background-color: white;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
}

.s_rowNotSelected:hover {
  background-color: #e6e6e6;
  cursor: pointer;
  color: black;
  border-bottom: solid 0.5px #00000075;
  /*border-bottom: solid 1px #e6e6e6;*/
}

</style>

